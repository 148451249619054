<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-layout class="patient-list" column>
    <v-card class="rounded-xl overflow-hidden" :disabled="disableWhileLoading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ getpatientN + "s" }}</div>
        <v-spacer />

        <v-btn :loading="loading" text @click="getPatients">
          <v-icon>mdi-autorenew</v-icon>
          {{ $t("labelUpdate") }}
        </v-btn>
      </v-toolbar>

      <div class="d-flex align-center pa-3">
        {{/* Filter */}}
        <v-text-field
          v-model.trim="query"
          class="mr-2"
          :label="'Search or filter ' + getpatientN"
          hide-details
          clearable
          outlined
          dense
        />
        <v-select
          v-if="isAdmins"
          v-model="createdByUuid"
          label="Filter by Employee"
          :items="workers"
          prepend-inner-icon="mdi-doctor"
          item-text="fullname"
          item-value="uuid"
          :loading="loading"
          hide-details
          clearable
          outlined
          dense
          @change="getPatients"
        />
        <v-select
          v-if="isAdmins"
          v-model="sellerUuid"
          :label="`Filter by ${getSellerN}`"
          :items="workers"
          prepend-inner-icon="mdi-doctor"
          item-text="fullname"
          item-value="uuid"
          :loading="loading"
          hide-details
          clearable
          outlined
          dense
          @change="getPatients"
        />
        <v-select
          v-if="isAdmins"
          v-model="doctorUuid"
          :label="`Filter by ${getCoordinatorN}`"
          :items="coordinators"
          prepend-inner-icon="mdi-doctor"
          item-text="fullname"
          item-value="uuid"
          :loading="loading"
          hide-details
          clearable
          outlined
          dense
          @change="getPatients"
        />
      </div>
      <v-divider />

      <v-data-table
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalpatient"
        :items="patients"
        :loading="disableWhileLoading"
        :footer-props="footerProps"
        :items-per-page="10"
        item-key="uuid"
        @click:row="showDetails"
      >
        <template v-slot:[`item.isMale`]="{ item }">
          {{ item.isMale ? $t("labelMale") : $t("labelFemale") }}
        </template>
        <template v-slot:[`item.laststatus`]="{ item }">
          {{ getStatus(item) }}
        </template>
        <not-records slot="no-data" />
      </v-data-table>
      <v-layout justify-center>
        <errors v-model="showErrors" class="mt-3" :message="errorMessage" />
      </v-layout>
    </v-card>

    {{/* Confirm remove */}}
  </v-layout>
</template>

<script>
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import Errors from "../PdfSign/Errors.vue";
import { AxiosError, AxiosResponse } from "axios";
import BtnRequestPatients from "./buttons/BtnRequestPatients.vue";
import PatientFilter from "./PatientFilter.vue";
import Patient from "@/models/Patient";
import { debounce } from "lodash";

export default Vue.extend({
  name: "crm-patients",
  components: {
    NotRecords,
    Errors,
    BtnRequestPatients,
    /*   PatientFilter, */
  },
  data() {
    return {
      signature: new Blob(),
      loadingSignStep1: false,
      showErrors: false,
      options: {},
      reqrange: {
        limit: 100,
        offset: 0,
      },
      errorMessage: "",
      loadingSendStep: false,
      footerProps: { "items-per-page-options": [10, 15, 25, 50, 100] },

      expanded: [],
      // The uuid of the worker to remove
      currentPatient: null,
      dialogEdit: false,

      dialogDocument: false,
      dialogSignStep1: false,

      query: null,
      doctorUuid: null,
      sellerUuid: null,
      createdByUuid: null,
      loadingQuery: false,
      isAdmins: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", [
      "patients",
      "loading",
      "totalpatient",
      "doctors",
      "agents",
    ]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "getCoordinatorN",
      "getSellerN",
      "getpatientN",
      "getviewpatientStatus",
    ]),
    ...mapState("crmEmployeeModule", ["workers", "coordinators"]),

    disableWhileLoading() {
      return this.loading || this.loadingSignStep1 || this.loadingSendStep;
    },

    headers() {
      if (this.getviewpatientStatus) {
        return [
          { text: this.$t("Fullname"), value: "fullname", sortable: false },
          { text: this.$t("labelGender"), value: "isMale", sortable: false },
          { text: "Email", value: "email", align: "start", sortable: false },
          {
            text: this.$t("labelHomePhone"),
            value: "homePhone",
            align: "start",
            sortable: false,
          },
          { text: "Status", value: "laststatus", sortable: false },
        ];
      }
      return [
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "start", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "start",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getPatients();
      }
    },
    query(val) {
      if (val == null) {
        this.getPatients();
      } else if (val.length >= 3 || val.length == 0) {
        this.getPatients();
      }
    },
  },
  async mounted() {
    if (this.isSuper || this.isAdmin) {
      this.isAdmins = true;
      await this.actGetEmployees();
      await this.actGetCoordinators();
    }
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListPatients",
      "actRemovePatient",
      "actGetPatientAgents",
      "actGetPatientDoctors",
      "actFilterPatient",
    ]),
    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetCoordinators",
    ]),
    ...mapMutations("crmMedicFormModule", ["mutSetPatients"]),
    ...mapMutations(["mutReqRange"]),
    getPatients() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.reqrange = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(this.reqrange);
      if (
        (this.query == null || this.query == "") &&
        this.doctorUuid == null &&
        this.sellerUuid == null &&
        this.createdByUuid == null
      ) {
        this.actListPatients(this.reqrange);
      } else {
        this.actFilterPatient({
          query: this.query,
          doctorUuid: this.doctorUuid,
          range: this.reqrange,
          sellerUuid: this.sellerUuid,
          createdByUuid: this.createdByUuid,
        });
      }
    },

    setPatientToSendDocument(patient) {
      this.currentPatient = patient;
      this.dialogDocument = true;
    },
    sendStep(step, patientUuid) {
      this.loadingSendStep = true;
      getAPI.post(`/patient/sendstep${step}/${patientUuid}`).then(() => {
        notifyInfo("The documents have been sended");
        this.loadingSendStep = false;
      });
    },
    showSignDialog(patient) {
      this.signature = new Blob();
      this.currentPatient = patient;
      this.dialogSignStep1 = true;
    },
    signAdminStep1() {
      const formData = new FormData();

      this.loadingSignStep1 = true;
      formData.append(
        "firma",
        this.signature,
        `signature-${this.currentPatient.uuid}`
      );
      getAPI({
        method: "post",
        url: "/patient/uploadAdminSign/" + this.currentPatient.uuid,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);

          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        });
    },
    showDetails(patient) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },

    getStatus(patient) {
      if (patient != undefined && patient != null) {
        const proceduresTemp = patient.proceduresEntity
          ? patient.proceduresEntity
          : [];
        if (proceduresTemp.length != 0) {
          return proceduresTemp[proceduresTemp.length - 1].dasboardStatus;
        }
        return "-";
      }

      return "-";
    },
  },
});
</script>

<style lang="scss">
.patient-list {
  .v-data-table {
    tbody {
      tr {
        cursor: pointer !important;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "Fullname": "Full name",
    "dialogTitle": "Reason why you open the details of :",
    "notification": "Successfully removed",
    "labelGender": "Gender",
    "labelHomePhone": "Phone",
    "labelEmergencyPhone": "Emergency phone"

  },
  "es": {
    "Fullname": "Nombre completo",
    "dialogTitle": "Razon por la que abre los detalles de :",
    "notification": "Eliminado con éxito",
    "labelGender": "Género",
    "labelHomePhone": "Teléfono",
    "labelEmergencyPhone": "Teléfono de emergencias"
  }
}
</i18n>
