































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import { mapActions, mapMutations, mapState } from "vuex";
import { notifyError } from "@/components/Notification";
import { debounce } from "lodash";
import { getAPI } from "@/api/axios-base";
import { AxiosResponse } from "axios";

export default Vue.extend({
  name: "patient-filter",
  props: {
    onlyemployee: Boolean,
  },
  data() {
    return {
      doctors_agents: [],
      rules,
      isAdmin: false,
      query: null,
      doctorUuid: null,
      loadingQuery: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["loading", "patients"]),
    ...mapState("crmEmployeeModule", ["workers"]),
  },
  watch: {
    query: debounce(function query(this: Vue, val: string): void {
      if (val == null || val == "") {
        (this as any).actListPatients();
      }

      (this as any).loadingQuery = true;
      getAPI
        .post("/patient/filterPatients", {
          query: val,
        })
        .then((response: AxiosResponse) => {
          (this as any).mutSetPatients(response.data);
          (this as any).loadingQuery = false;
        });
    }, 400),
  },
  async mounted() {
    if (
      this.$store.state.profile.rol == "ADMIN" ||
      this.$store.state.profile.rol == "SUPER"
    ) {
      this.isAdmin = true;
      await this.actGetEmployees();
    }
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actFilterPatient",
      "actListPatients",
    ]),
    ...mapMutations("crmMedicFormModule", ["mutSetPatients"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    submit(doctorUuid: string) {
      let errors: string[] = [];

      if (errors.length > 0) {
        const messageErrors = errors.map((error) => error + "<br/>").join("");

        notifyError(messageErrors);
        return;
      }
      if (doctorUuid == null) {
        this.actListPatients();
        this.$emit("doctor", false);
      } else {
        this.actFilterPatient({
          doctorUuid: doctorUuid,
        });
        this.$emit("doctor", true);
      }
    },
    reset() {
      this.doctorUuid = null;
    },
  },
});
